import React from "react"
import Layout from "../../components/layout-static"
import Seo from "../../components/seo"

import { Link } from "gatsby"

const Page = () => {
  const metadata = {
    title: "Connecticut Child Sexual Abuse Attorney",
    heroH1: "Connecticut Child Sexual Abuse Attorney",
    heroH2: "When experience, integrity, and results matter.",
    description:
      "At Bartlett & Grippe, we help child sexual abuse victims seek justice against perpetrators who exploit authority positions. Contact us for closure and maximum compensation.",
  }
  return (
    <Layout metadata={metadata}>
      <Seo title={metadata.title} description={metadata.description} />
      <div
        id="content"
        className="flex flex-wrap container mx-auto px-4 xl:px-0"
      >
        <div className="w-full pt-8 xl:pt-0">
          <div className="mb-16">
            <h2>Working with Child Sexual Abuse Victims and Families</h2>
            <p className="mb-8">
              In child sexual abuse cases the well-being of the victim is our
              primary focus. The objective is to give victims a voice, see that
              justice is served, provide closure, and maximize compensation.
              Child sexual abuse Attorney Bartlett recently won a $2,750,000
              jury verdict associated with child sexual abuse that occurred over
              forty years ago. The victim said the trial brought her
              vindication, closure, and much-needed relief. She finally felt it
              was not her fault.
            </p>
            <p className="mb-8">
              Perpetrators of sexual abuse often utilize authority positions and
              the status and trust associated with those positions to befriend
              and exploit innocent children. Whether at educational institutions
              such as the Indian Mountain Boarding School in Lakeville CT or
              Newington High School, religious institutions such as the Catholic
              Church or the United Church of Christ, or civic organizations such
              as the Boy Scouts of America, children are taught to respect those
              in authority positions. Bartlett & Grippe has the experience to
              help adult victims of childhood sexual abuse.
            </p>
            <p className="mb-8">
              Unwanted sexual contact by a family member adds a particularly
              emotionally charged dynamic. Clients are encouraged to seek the
              treatment and counseling they need, either ahead of any legal
              action or running concurrently with a lawsuit.
            </p>
            <h2>CT Foster Parent And Family Member Sexual Abuse Lawyer</h2>
            <p className="mb-8">
              Foster parents wishing to open their homes to children should be
              thoroughly vetted prior to any licensure. Once a child is placed,
              Connecticut state agencies have the responsibility to provide a
              licensed and trained social worker to visit the home regularly.
              Part of that training involves identifying sexual abuse and
              molestation. Suspicion should lead to immediate investigations and
              possible removal of the child. Failure of the state’s
              representative to act not only allows the abuse to continue, but
              it also puts the state at risk of legal action along with the
              abusing foster parent.
            </p>
            <p className="mb-8">
              At the initial consultation, clients receive immediate validation
              as we spend time with you to understand the events that led you to
              seek legal counsel. In cases involving a family member, treatment
              and counseling are necessary for the victim, abuser, and other
              family members. The focus is to hold the abuser and state
              agencies, when appropriate, accountable. When abuse is committed
              by a foster parent or close relative, additional issues need
              resolution. Preserving familial relationships are vital. The
              future of a family unit is often at stake and requires attention
              as well.
            </p>
            <h3>
              We represent clients with sexual abuse by Boy Scout leaders,
              priests, medical professionals, teachers and others
            </h3>
            <ul className="list-disc list-outside ml-6">
              <li>
                <Link to="/ct-sex-abuse-attorney/child-sexual-abuse-attorney/">
                  Sexual abuse home page
                </Link>
              </li>
              <li>
                <Link to="/ct-sex-abuse-attorney/sexual-abuse-by-priests/">
                  Priest
                </Link>
              </li>
              <li>
                <Link to="/ct-sex-abuse-attorney/child-sexual-abuse-attorney/">
                  Family member, foster parent
                </Link>
              </li>
              <li>
                <Link to="/ct-sex-abuse-attorney/ct-boy-scout-sexual-abuse-lawyer/">
                  Boy Scout leader
                </Link>
              </li>
              <li>
                <Link to="/ct-sex-abuse-attorney/child-sexual-abuse-attorney/">
                  Teacher
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Page
